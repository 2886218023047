import Color from "@lxweb/color";
import { gameAssets } from "../..";

const mainColor = new Color("hsl(160, 7%, 25%)"); // green
const accentColor = new Color("hsl(345, 90%, 60%)"); // red
const backgroundColor = new Color({
	parentColor: mainColor,
	offsets: { saturation: sat => sat / 4, light: light => (light >= 50 ? 12 : 91) }, // High contrast with main color
});
const visibleColor = new Color({ parentColor: mainColor, saturation: 70, light: 40 });
const pauseColor = new Color({
	parentColor: mainColor,
	alpha: 70,
	offsets: { saturation: sat => sat / 2, light: light => (light >= 20 ? light - 20 : light + 20) },
});
const textOnPauseColor = new Color({
	parentColor: pauseColor,
	alpha: 100,
	offsets: { saturation: sat => sat / 2, light: light => (light >= 50 ? 12 : 91) },
});

const colors = {
	main: mainColor.toHsl(),
	accent: accentColor.toHsl(),
	background: backgroundColor.toHsl(),
	visible: visibleColor.toHsl(),
	pause: pauseColor.toHsl(),
	textOnPause: textOnPauseColor.toHsl(),
};
export default colors;

function setCSSCustomColors() {
	document.documentElement.style.setProperty("--main", colors.main);
	document.documentElement.style.setProperty("--accent", colors.accent);
	document.documentElement.style.setProperty("--background", colors.background);
	document.documentElement.style.setProperty("--visible-main", colors.visible);
}
setCSSCustomColors();

function updateColors() {
	colors.main = mainColor.toHsl();
	colors.accent = accentColor.toHsl();
	colors.background = backgroundColor.toHsl();
	colors.visible = visibleColor.toHsl();
	colors.pause = pauseColor.toHsl();
	colors.textOnPause = textOnPauseColor.toHsl();

	setCSSCustomColors();
}

export function setMainColor(newColor) {
	mainColor.set(newColor);
	updateColors();
	gameAssets.gameArt.color = newColor;
}

export function setAccentColor(newColor) {
	accentColor.set(newColor);
	updateColors();
}
